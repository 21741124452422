import { Button, Form, Input, Modal, notification, Select, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import CustomSearch from '../../../../../components/Common/CustomSearch/CustomSearch'
import ModalDetailComponent from '../../../../../components/Common/ModalDetails/Detail'
import FormCreation from '../../../../../components/Common/ModalDetails/FormCreation'
import InputRadio from '../../../../../components/Common/ModalDetails/InputRadio'

import Define from '../../../../../constants/define'
import {
  ActionType,
  FormCreationType,
  StatusAPI,
  TableType,
} from '../../../../../enum'
import fournisseurApi from '../../../../../http/fournisserApi'

import clsx from 'clsx'
import dayjs from 'dayjs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import SUCCESS from '../../../../../assets/images/success.png'
import Loading from '../../../../../components/Common/Loading/Loading'
import { MODE } from '../../../../../enum/mode'
import { StatusAttendu } from '../../../../../enum/StatusAttendu'
import { focusById } from '../../../../../hook/usePressTab'
import attenduApi from '../../../../../http/attenduApi'
import {
  Adresses,
  AttCommon,
  Contact,
  ReferenceAttendu,
} from '../../../../../models'
import { CommonError } from '../../../../../models/common/error'
import { initialAttendu } from '../../../../../redux/reducers/attenduSlice'
import {
  clearCurrentFournisseur,
  getFournisseurByCode,
} from '../../../../../redux/reducers/fournisseurSlice'
import {
  getCurrentFiltersText,
  removeTones,
  renderNull,
} from '../../../../../utils'
import { mapDataDetail } from '../../../../../utils/attendu/mapDataDetail'
import { DataTableParam } from '../../../stockscreen/component/model'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import { NameAndValue } from '../../../stockscreen/component/Wrapper'
import '../../CustomModal.scss'
import { ModalName, updateAttenduStatus } from '../../customModalSlice'
import './Attendu.scss'
import CDatePicker from '../../../../../components/Common/CDatePicker'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

const AttenduCreate = () => {
  const navigate = useNavigate()
  const { currentFournisseur, currentFournisseurEdit } = useSelector(
    (state: RootState) => state.fournisseur
  )
  const { t } = useTranslation()
  const dateFormat = t('date-format')

  const [isCreateFournisseur, setIsCreateFournisseur] = useState<boolean>(false)
  const [dataFournisseurAdresses, setDataFournisseurAdresses] = useState<
    Adresses[]
  >([])
  const [dataFournisseurContact, setDataFournisseurContact] = useState<
    Contact[]
  >([])
  const [detailData, setDetailData] = useState<DataTableParam[][]>([])
  const [isValid_detailData, set_isValid_detailData] = useState<boolean>(false)
  const [isShowAlertDetail, setIsShowAlertDetail] = useState<boolean>(false)

  const [isExistAttendu, setIsExistAttendu] = useState<string>('')
  const [isExistNoFournisseur, setIsExistNoFournisseur] = useState<string>('')
  const client = localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''

  const refToTop = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const detail = useSelector((state: RootState) => state.attendu.detail)
  const [errorDetail, setErrorDetail] = useState<CommonError[]>([])

  const [attFournisseur, setAttFournisseur] = useState<AttCommon>()
  const [form] = Form.useForm()
  const [numberOfSubmit, setNumberOfSubmit] = useState<number>(0)
  const [numberOfPhotos, setNumberOfPhotos] = useState<number>(0)
  const initialValues = {
    commande: dayjs(dayjs(), dateFormat),
    priorite: 5,
  }
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index'))
  const pageSize = Number(searchParams.get('page-size'))
  const [loading, setLoading] = useState<boolean>(false)
  const attenduKey = useSelector((state: RootState) => state.selector).data
    .attendu

  const columnsAdresses: ColumnsType<Adresses> = [
    {
      title: <div className="form-label">Adresse</div>,
      dataIndex: 'addresse',
      key: 'addresse',
      width: 370,
      render: (_, row) =>
        `${renderNull(row.addresse as string)} - ${renderNull(
          row.code_postal as string
        )} - ${renderNull(row.ville as string)} - ${renderNull(
          row.pays as string
        )} (${renderNull(row.iso as string)})`,
    },
    {
      title: <div className="form-label">Complément</div>,
      dataIndex: 'complement',
      key: 'complement',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">GLN</div>,
      dataIndex: 'gln',
      key: 'gln',
      width: 110,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Actions</div>,
      key: 'action',
      width: 110,
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.ADDRESSE}
          handleCheckedRadio={() => handleAttFounisseur(TableType.ADDRESSE, el)}
        />
      ),
    },
  ]

  const columnsContact: ColumnsType<Contact> = [
    {
      title: <div className="form-label">Contact</div>,
      dataIndex: 'nom',
      key: 'contact',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Tel</div>,
      dataIndex: 'tel',
      key: 'tel',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Mobile</div>,
      dataIndex: 'mobile',
      key: 'mobile',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Email</div>,
      dataIndex: 'email',
      key: 'email',
      width: 225,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Actions</div>,
      key: 'action',
      width: 110,
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.CONTACT}
          handleCheckedRadio={() => handleAttFounisseur(TableType.CONTACT, el)}
        />
      ),
    },
  ]

  const handleAttFounisseur = (type: string, value: any) => {
    let myAttFournisseur: AttCommon = attFournisseur || {}
    switch (type) {
      case TableType.ADDRESSE:
        myAttFournisseur.addresse = value.addresse
        myAttFournisseur.code_postal = value.code_postal
        myAttFournisseur.ville = value.ville
        myAttFournisseur.pays = value.pays
        myAttFournisseur.iso = value.iso
        myAttFournisseur.gln = value.gln
        myAttFournisseur.complement = value.complement
        break
      case TableType.CONTACT:
        myAttFournisseur.contact = value.nom
        myAttFournisseur.tel = value.tel
        myAttFournisseur.mobile = value.mobile
        myAttFournisseur.email = value.email
        break
      default:
        break
    }
    setAttFournisseur(myAttFournisseur)
  }

  useEffect(() => {
    if (!currentFournisseur.fournisseur) {
      setAttFournisseur({})
      return
    }
    setDataFournisseurAdresses(
      currentFournisseur.fournisseur?.addresse as Adresses[]
    )
    setDataFournisseurContact(
      currentFournisseur.fournisseur?.contact as Contact[]
    )
    setAttFournisseur({
      code_nom:
        currentFournisseur.fournisseur?.code +
        '_' +
        currentFournisseur.fournisseur?.nom,
      addresse: currentFournisseur.fournisseur?.addresse[0].addresse,
      code_postal: currentFournisseur.fournisseur?.addresse[0].code_postal,
      ville: currentFournisseur.fournisseur?.addresse[0].ville,
      pays: currentFournisseur.fournisseur?.addresse[0].pays,
      iso: currentFournisseur.fournisseur?.addresse[0].iso,
      complement: currentFournisseur.fournisseur?.addresse[0].complement,
      gln: currentFournisseur.fournisseur?.addresse[0].gln,
      contact: currentFournisseur.fournisseur?.contact[0].nom,
      tel: currentFournisseur.fournisseur?.contact[0].tel,
      mobile: currentFournisseur.fournisseur?.contact[0].mobile,
      email: currentFournisseur.fournisseur?.contact[0].email,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFournisseur])

  //update search field if current fournisseur exist
  useEffect(() => {
    if (!currentFournisseur.fournisseur) return

    form.setFieldValue(
      'fournisseur',
      `${currentFournisseur.fournisseur?.code}_${currentFournisseur.fournisseur?.nom}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFournisseur, isCreateFournisseur])

  useEffect(() => {
    //reset field after close popup edit

    form.resetFields()
    setErrorDetail([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      isCreateFournisseur &&
      currentFournisseur.status === StatusAPI.success &&
      currentFournisseur.action === ActionType.CREATE
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Création enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsCreateFournisseur(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFournisseur.status])

  const onChangeNoFournisseur = (newValue: string) => {
    document
      .getElementById('number_of_fournisseur')
      ?.classList.remove('red-border')
    setIsExistNoFournisseur('')
    form.setFieldValue('number_of_fournisseur', removeTones(newValue))
  }

  const onChangeNumber = (newValue: string) => {
    setIsExistAttendu('')
    document
      .getElementById('number_of_fournisseur')
      ?.classList.remove('red-border')
    form.setFieldValue('bl', removeTones(newValue))
  }

  const onChangeFournisseur = (newValue: NameAndValue, code: string) => {
    if (newValue.name) {
      dispatch(getFournisseurByCode({ code }))
    }
  }

  const onChangeDetailData = (data: DataTableParam[][]) => {
    setDetailData(data)
  }

  const onCheckValidDetailData = (isValid: boolean) => {
    set_isValid_detailData(isValid)
  }

  useEffect(() => {
    if (!isValid_detailData && numberOfSubmit > 0) {
      setIsShowAlertDetail(true)
    }
    if (isValid_detailData && numberOfSubmit > 0) {
      setIsShowAlertDetail(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  const onChangePhoto = (value: number) => {
    setNumberOfPhotos(value)
  }

  const onFinishForm = (value: any) => {
    if (numberOfPhotos > 20) return

    setNumberOfSubmit(numberOfSubmit + 1)
    let attProduction = mapDataDetail(detailData)

    let att_production = (attProduction || []).map((item) => {
      item.created_by = localStorage.getItem(Define.USERNAME) || ''
      item.status = StatusAttendu.CREATED
      return item
    })

    const isEmpty = att_production.some(
      (item) => !Boolean(item.reference_number)
    )
    if (isEmpty) att_production = []

    value.att_production = value.fournisseur ? att_production : []
    value.att_fournisseur = attFournisseur
    if (isValid_detailData) {
      setLoading(true)
      const needLinkReferenceList =
        form.getFieldValue('needLinkReferenceList') || []
      let newRefFours: any[] = []
      value.att_production?.forEach((att: ReferenceAttendu) => {
        if (
          !!needLinkReferenceList.find(
            (item: { ref_nom: string; reference_fournisser: string }) =>
              item.ref_nom === att.reference_number &&
              item.reference_fournisser === att.ref_fournisseur_number
          ) &&
          !newRefFours.find(
            (item) =>
              item.ref_nom === att.reference_number &&
              item.reference_id === att.reference_id
          )
        ) {
          newRefFours.push({
            client_code_nom:
              localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
            fournisser_id:
              currentFournisseur?.fournisseur?.id || currentFournisseurEdit?.id,
            ref_nom: att.reference_number,
            reference_fournisser: att.ref_fournisseur_number,
            reference_id: att.reference_id,
          })
        }
      })

      attenduApi
        .createAttendu({
          bl: value.bl,
          create_at: 0,
          update_at: 0,
          commande: value.commande?.unix() || 0,
          livraison: value.livraison?.unix() || 0,
          number_of_fournisseur: value.number_of_fournisseur,
          priorite: value.priorite,
          att_commentaire: value.att_commentaire,
          att_production: value.att_production,
          att_fournisseur: value.att_fournisseur,
          qte_total: 1122,
          sous_qte_total: 1122,
          fournisseur_id:
            currentFournisseur?.fournisseur?.id || currentFournisseurEdit?.id,
          new_ref_fours: newRefFours,
          no_commande: value.no_commande,
          photos: numberOfPhotos || 0,
          company_code_nom:
            localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
          warehouse_code_nom:
            localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
          client_code_nom:
            localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
          created_by: localStorage.getItem(Define.USERNAME) || '',
          creater_id: localStorage.getItem(Define.USER_ID) || '',
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
        })
        .then((res) => {
          let result = res.data.entry
          dispatch(updateAttenduStatus(StatusAttendu.CREATED))
          dispatch(clearCurrentFournisseur())
          navigate(
            `/gestion-de-stock/attendu/attendu-edit/${
              result.id
            }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`,
            {
              replace: true,
            }
          )
        })
        .catch((e) => {
          let error = e.response.data.error
          if (
            error &&
            (error.includes('attendus_bl_key') ||
              error.includes('idx_attendus_number'))
          ) {
            setIsExistAttendu('Numéro déjà existant')
            refToTop.current && refToTop.current.scrollIntoView()
            document.getElementById('bl')?.classList.add('red-border')
            setErrorDetail([])
          } else {
            setIsExistAttendu('')
          }

          if (error && error.includes('number_of_fournisseur')) {
            setIsExistNoFournisseur('Numéro déjà existant')
            refToTop.current && refToTop.current.scrollIntoView()
            document
              .getElementById('number_of_fournisseur')
              ?.classList.add('red-border')
          } else {
            setIsExistNoFournisseur('')
            document
              .getElementById('number_of_fournisseur')
              ?.classList.remove('red-border')
            setErrorDetail([])
          }

          if (error && error.includes('sscc')) {
            setErrorDetail([
              ...errorDetail,
              {
                name: 'sscc',
                errorMessage: 'duplicated SSCC',
              },
            ])
          }

          if (
            (error &&
              !(
                error.includes('attendus_bl_key') ||
                error.includes('idx_attendus_number')
              ) &&
              !error.includes('number_of_fournisseur') &&
              !error.includes('sscc')) ||
            !error
          ) {
            notification.open({
              className: 'noti noti-error',
              message: (
                <div className="flex items-center">
                  <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                  Une erreur s'est produite. Merci de réessayer ultérieurement
                </div>
              ),
              placement: 'topRight',
              closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
              duration: 3,
            })
            setErrorDetail([])
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleClose = () => {
    form.resetFields()

    dispatch(initialAttendu())
    dispatch(clearCurrentFournisseur())

    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate(
        `/gestion-de-stock/attendu?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    }
  }

  return (
    <Form
      id={ModalName.ATTENDU}
      onFinish={onFinishForm}
      form={form}
      initialValues={initialValues}
      onFinishFailed={() => setNumberOfSubmit(numberOfSubmit + 1)}
    >
      <Modal
        prefixCls="modal-new-stock"
        centered
        open={true}
        onCancel={handleClose}
        width={'90%'}
        className="border-solid border-secondary rounded-xl"
        footer={null}
        destroyOnClose={true}
      >
        {loading && <Loading />}
        <div
          className="flex text-primary text-2xl font-bold items-baseline border-none pb-2 mb-4"
          style={{ borderBottom: '1px solid #B7B7B7' }}
        >
          <span className="mr-4 modal-title-heading-1">Création</span>
          <span className="modal-title-heading-2 mr-4">Attendu</span>
        </div>
        <div ref={refToTop} className="overflow-y-auto main-content ">
          <SectionLayout title="Informations">
            <div className="flex justify-between infomation-section">
              <div className="flex flex-col">
                <Form.Item
                  label="N° de BL"
                  name="bl"
                  rules={[{ required: true, message: '' }]}
                  colon={false}
                  validateStatus={isExistAttendu ? 'error' : undefined}
                  help={isExistAttendu}
                  className="justify-between"
                >
                  <Input
                    autoFocus
                    id={attenduKey['n-input']}
                    data-next-id={attenduKey['Commande']}
                    tabIndex={0}
                    style={{ width: '100%' }}
                    maxLength={20}
                    onChange={(e) => onChangeNumber(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="N° Fournisseur"
                  name="number_of_fournisseur"
                  colon={false}
                  validateStatus={isExistNoFournisseur ? 'error' : undefined}
                  help={isExistNoFournisseur}
                >
                  <Input
                    id={attenduKey['N-Fournisseur']}
                    data-next-id={attenduKey['N-Commande']}
                    data-previous-id={attenduKey['Priorité']}
                    style={{ width: '100%' }}
                    maxLength={20}
                    onChange={(e) => {
                      onChangeNoFournisseur(e.target.value)
                    }}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item label="Commande" name="commande" colon={false}>
                  <CDatePicker
                    id={attenduKey['Commande']}
                    data-next-id={attenduKey['Livraison']}
                    data-previous-id={attenduKey['n-input']}
                    placement="topLeft"
                  />
                </Form.Item>
                <Form.Item label="N° Commande" name="no_commande" colon={false}>
                  <Input
                    id={attenduKey['N-Commande']}
                    data-next-id={`
                      ${attenduKey['Code-Nom-Fournisseur']}, 
                      ${FormCreationType.FOURNISSEUR}-nom, 
                      ${attenduKey['Filtre-fournisseur']},
                      ${attenduKey['supprimer']}
                    `}
                    data-previous-id={attenduKey['N-Fournisseur']}
                    style={{ width: '100%' }}
                    maxLength={20}
                    onChange={(e) => {
                      form.setFieldValue(
                        'no_commande',
                        removeTones(e.target.value)
                      )
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Livraison" name="livraison" colon={false}>
                  <CDatePicker
                    id={attenduKey['Livraison']}
                    data-next-id={attenduKey['Priorité']}
                    data-previous-id={attenduKey['Commande']}
                    disabledDate={(current) => {
                      return moment().add(-1, 'days') >= current
                    }}
                    placement="topLeft"
                    style={{ width: 180 }}
                  />
                </Form.Item>
                <Form.Item label="Client" name="client" colon={false}>
                  <Input
                    disabled
                    style={{ background: '#F2F2F2' }}
                    value={client}
                    placeholder={client}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Priorité"
                  name="priorite"
                  colon={false}
                  className="priorite-item"
                >
                  <Select
                    id={attenduKey['Priorité']}
                    aria-roledescription={attenduKey['N-Fournisseur']}
                    aria-keyshortcuts={attenduKey['Livraison']}
                    style={{ width: 87, marginLeft: 60 }}
                  >
                    {[5, 4, 3, 2, 1].map((i) => (
                      <Select.Option key={i} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </SectionLayout>
          {isCreateFournisseur ? (
            <FormCreation
              type={FormCreationType.FOURNISSEUR}
              isCreateForm={isCreateFournisseur}
              onCloseForm={() => setIsCreateFournisseur(false)}
              previousId={attenduKey['N-Commande']}
              nextId={attenduKey['Filtre-fournisseur']}
            />
          ) : (
            <SectionLayout
              title="Fournisseur"
              action={
                <Action
                  onClickPlusBtn={() => {
                    focusById(`${FormCreationType.FOURNISSEUR}-nom`, 300)
                    setIsCreateFournisseur(true)
                  }}
                />
              }
            >
              <div style={{ overflow: 'auto' }}>
                <Form.Item
                  label="Code & Nom Fournisseur"
                  colon={false}
                  name="fournisseur"
                  className="form-label"
                >
                  <CustomSearch
                    id={attenduKey['Code-Nom-Fournisseur']}
                    previousId={attenduKey['N-Commande']}
                    nextId={attenduKey['Filtre-fournisseur']}
                    disabled={
                      detail?.validAtLeastOneLine &&
                      Boolean(currentFournisseur.fournisseur)
                    }
                    onChange={onChangeFournisseur}
                    functionSearch={
                      fournisseurApi.searchFournisseurByNameOrCode
                    }
                    className="attendu-custom-search"
                  />
                </Form.Item>
                <div className="flex justify-between">
                  {currentFournisseur.fournisseur && (
                    <>
                      <Table
                        bordered
                        columns={columnsAdresses}
                        loading={
                          currentFournisseur.status === StatusAPI.calling
                        }
                        dataSource={dataFournisseurAdresses}
                        className="custom-common-modal mr-12"
                        pagination={false}
                      />
                      <Table
                        bordered
                        columns={columnsContact}
                        loading={
                          currentFournisseur.status === StatusAPI.calling
                        }
                        dataSource={dataFournisseurContact}
                        className="custom-common-modal"
                        pagination={false}
                      />
                    </>
                  )}
                </div>
              </div>
            </SectionLayout>
          )}

          <ModalDetailComponent
            mode={MODE.CREATE}
            // initData={initTestData}
            onChangeData={onChangeDetailData}
            onCheckValid={onCheckValidDetailData}
            alert={
              isShowAlertDetail ? (
                alertNode('Veuillez valider toutes ces lignes suivantes')
              ) : (
                <></>
              )
            }
            detailError={errorDetail}
            onChangePhoto={onChangePhoto}
          />
          <SectionLayout title="Commentaire">
            <Form.Item name={['att_commentaire', 'content']}>
              <TextArea
                id={attenduKey['Commentaire']}
                data-next-id={attenduKey['Sauvegarder']}
                style={{
                  height: 100,
                  width: 390,
                  resize: 'none',
                  marginLeft: '7%',
                }}
                showCount
                maxLength={200}
              />
            </Form.Item>
          </SectionLayout>
        </div>
        <div className="footer-content flex justify-end items-center">
          <Button
            id={attenduKey['Sauvegarder']}
            data-previous-id={attenduKey['Commentaire']}
            data-next-id={attenduKey['n-input']}
            disabled={loading}
            prefixCls=" text-btn-submit-last-modal"
            className={clsx(
              'btn-submit-modal text-btn-submit-last-modal',
              !loading ? 'cursor-pointer' : 'cursor-not-allowed'
            )}
            key="submit"
            htmlType="submit"
            form={ModalName.ATTENDU}
          >
            Sauvegarder
          </Button>
        </div>
      </Modal>
    </Form>
  )
}

export default AttenduCreate

const alertNode = (text: string): ReactNode => (
  <span
    style={{ fontSize: 12, color: 'red', marginLeft: 5 }}
  >{`(${text})`}</span>
)
